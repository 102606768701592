<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn :to="{ name: 'CabinetIndex' }" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title v-if="currentUser"
        >Баланс: {{ currentUser.balance }} р. ({{
          currentUser.reserve
        }}
        р.)</v-toolbar-title
      >
      <template v-slot:extension>
        <v-tabs v-model="balanceTab" centered grow>
          <v-tab>Вывести</v-tab>
          <!-- <v-tab>Пополнить</v-tab> -->
        </v-tabs>
      </template>
    </v-toolbar>
    <v-container v-if="!loadPage" class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8">
          <v-tabs-items class="flex-grow-1" v-model="balanceTab">
            <v-tab-item>
              <v-card class="elevation-0">
                <v-form v-model="validMinus" ref="formMinus">
                  <v-subheader class="px-0"
                    >Коммиссия {{ out_commission }}%. Вы получите
                    {{ outSumm }} руб.</v-subheader
                  >
                  <v-text-field
                    v-model="summMinus"
                    name="inputSummMinus"
                    label="Сумма"
                    required
                    :rules="balanceMinusRules"
                  ></v-text-field>

                  <v-btn dark color="primary" @click="balanceMinus"
                    >Вывести</v-btn
                  >
                </v-form>
              </v-card>
            </v-tab-item>
            <!-- <v-tab-item>
              <v-card class="elevation-0">
                <v-form v-model="validPlus" ref="formPlus">
                  <v-text-field
                    v-model="summPlus"
                    name="inputSummPlus"
                    label="Сумма"
                    required
                    :rules="balanceRules"
                  ></v-text-field>

                  <v-btn dark color="primary" @click="balancePlus">Пополнить</v-btn>
                </v-form>
              </v-card>
            </v-tab-item> -->
          </v-tabs-items>

          <v-subheader class="mt-4">История операций</v-subheader>
          <v-row
            v-if="!transactions || transactions.length == 0"
            align="center"
          >
            <no-content route-name title="Данных нет"></no-content>
          </v-row>
          <v-data-table
            v-else
            :headers="headersTable"
            :items="transactions"
            :items-per-page="15"
            class
            item-key="id_table"
          ></v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  //mixins: [snackbarError],
  data: () => ({
    validPlus: true,
    validMinus: true,
    balanceTab: 0,
    summPlus: 0,
    summMinus: 0,
    headersTable: [
      {
        text: "Дата",
        align: "start",
        value: "date",
      },
      { text: "Сумма", value: "summ" },
      { text: "Тип", value: "type_formated" },
      { text: "Статус", value: "status_formated" },
    ],
    requiredRules: [(v) => !!v || "Поле обязательно"],
    titleRules: [
      (v) => !!v || "Поле обязательно",
      (v) => v.length >= 3 || "Минимум 3 символа",
    ],
    balanceRules: [
      // v => !!v || 'Поле обязательно',
      (v) => !!v === v >= 100 || "Минимум 100 р",
    ],
    commission: 0, //Комиссия в процентах
  }),
  methods: {
    validatePlus() {
      return this.$refs.formPlus.validate();
    },
    validateMinus() {
      return this.$refs.formMinus.validate();
    },
    balancePlus() {
      if (!this.validatePlus()) return;
      let app = this;
      this.axios
        .post("/add-in-transaction", {
          summ: app.summPlus,
        })
        .then(function (response) {
          if (response.data.success == 1) {
            if (response.data.linkPay) {
              // window.open(response.data.linkPay, "_blank");
              window.location.href = response.data.linkPay;
            }

            if (response.data.transactions) {
              app.$store.commit(
                "cabinet_balance/GET_TRANSACTIONS",
                response.data.transactions
              );
            }

            app.summPlus = 0;
            app.validPlus = true;
          }
        });
    },
    balanceMinus() {
      if (!this.validateMinus()) return;
      let app = this;
      this.$store
        .dispatch("cabinet_balance/balanceMinus", {
          summ: app.summMinus,
        })
        .then(() => {
          app.summMinus = 0;
          app.validMinus = true;
        });

      // axios
      //   .post("/add-out-transaction", {
      //     summ: app.summMinus
      //   })
      //   .then(function(resp) {
      //     app.loading = false;
      //     if (response.data.success == 1) {
      //       bus.$emit("show-modal-error", [true, response.data, "success"]);
      //       if (response.data.user) {
      //         // this.$store.commit(types.FETCH_USER_SUCCESS);
      //         app.$store.commit("FETCH_USER_SUCCESS", {
      //           user: response.data.user
      //         });
      //       }
      //       if (response.data.transactions) {
      //         app.transactions = response.data.transactions;
      //       }
      //       app.summMinus = 0;
      //     }
      //   })
      //   .catch(function(error) {
      //     app.loading = false;
      //     //console.log(error.response.data);
      //     bus.$emit("show-modal-error", [true, error.response.data, "error"]);
      //   });
    },
    getTransactions() {
      // let app = this;
      this.$store.dispatch("cabinet_balance/getTransactions");
      // axios
      //   .get("/get-outin-transactions")
      //   .then(function(resp) {
      //     app.loading = false;
      //     if (response.data.success == 1) {
      //       app.transactions = response.data.transactions;
      //     }
      //     app.loadPage = true;
      //   })
      //   .catch(function(error) {
      //     app.loading = false;
      //     bus.$emit("show-modal-error", [true, error.response.data, "error"]);
      //     app.loadPage = true;
      //   });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      transactions: "cabinet_balance/transactions",
      out_commission: "cabinet_balance/out_commission",
    }),
    balanceMinusRules() {
      const rules = [];

      if (this.currentUser) {
        const rule = (v) => (v && v >= 1000) || "Минимум 1000 р";
        const rule2 = (v) =>
          (v && v <= this.currentUser.balance) || "Недостаточно средств";
        rules.push(rule);
        rules.push(rule2);
      }

      return rules;
    },
    outSumm() {
      if (this.out_commission && this.summMinus) {
        let comm = Math.ceil((this.summMinus * this.out_commission) / 100);
        return this.summMinus - comm;
      }

      return this.summMinus;
    },
  },
  mounted() {
    this.getTransactions();
  },
};
</script>
